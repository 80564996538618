<template>
    <p>
        Si se cancela el evento, se le reembolsará su paquete de acuerdo con la <a :href="`${purchasePolicyUrl}#Canceled`" target="_blank">Sección 10</a> y la <a :href="`${purchasePolicyUrl}#Refunds`" target="_blank">Sección 11</a> de la <a :href="purchasePolicyUrl" target="_blank">Política de compra de Ticketmaster</a>, incluido el alojamiento. Los complementos como recuerdos, envío urgente y seguros no son reembolsables. No nos hacemos responsables de viajes adicionales ni de otros gastos.
    </p>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        computed: {
            purchasePolicyUrl( ) {
                return tenantUrlsConstants.purchasePolicy;
            }
        }
    };
</script>
