
<template>
    <component :is="component" :purchase-flow="purchaseFlow" :country="country" />
</template>
<script>
    import { mapState } from 'vuex';
    import EventCancellationPolicy_en from './EventCancellationPolicy_en.vue';
    import EventCancellationPolicy_es from './EventCancellationPolicy_es.vue';
    import EventCancellationPolicy_fr from './EventCancellationPolicy_fr.vue';

    export default {
        name: 'EventCancellationPolicy',
        components: {
            /* eslint-disable vue/no-unused-components */
            EventCancellationPolicy_en,
            EventCancellationPolicy_es,
            EventCancellationPolicy_fr,
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            purchaseFlow: {
                type: String,
                default: ''
            },
            country: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapState( {
                language:  state => state.appState.language
            } ),
            component() {
                return `EventCancellationPolicy_${this.language}`;
            }
        }
    };
</script>
