<template>
    <p>
        Les événements en direct peuvent changer en raison de circonstances indépendantes de notre volonté, et parfois à la dernière minute. Si l'événement est annulé avant le début de votre forfait, vous serez remboursé pour votre forfait conformément à la Section 6 de la <a :href="tenantUrlsConstantsForAu.purchasePolicy.en" target="_blank">Politique d'Achat</a>, incluant l'hébergement et les expériences. Si l'événement est annulé le jour même ou après le début de votre forfait, des remboursements partiels peuvent s'appliquer comme détaillé dans la <a :href="tenantUrlsConstantsForAu.travelExperiencePolicy.en" target="_blank">Politique de Voyages et d'Expériences</a>. Nous ne sommes pas responsables des frais de voyage supplémentaires ou autres dépenses.
    </p>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        computed: {
            tenantUrlsConstantsForAu( ) {
                return tenantUrlsConstants.COUNTRIES.AU;
            }
        }
    };
</script>
