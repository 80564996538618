<template>
    <p>
        If the event is cancelled, you will be refunded for your package in accordance with <a :href="`${purchasePolicyUrl}#Canceled`" target="_blank">Section 10</a> and <a :href="`${purchasePolicyUrl}#Refunds`" target="_blank">Section 11</a> of the <a :href="purchasePolicyUrl" target="_blank">Ticketmaster Purchase Policy</a>, inclusive of accommodation. Add-ons like memorabilia, expedited shipping, and insurance are not refundable. We are not liable for ancillary travel or other expenses.
    </p>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        computed: {
            purchasePolicyUrl( ) {
                return tenantUrlsConstants.purchasePolicy;
            }
        }
    };
</script>
