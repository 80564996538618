<template>
    <div class="gst-event-cancellation-policy__content">
        <component :is="component" v-if="component" />
        <template v-else>
            En cas d'annulation de l'événement, votre billet vous est remboursé conformément à l'<a :href="`${purchasePolicyUrl}#Canceled`" target="_blank">Article 10</a> et l'<a :href="`${purchasePolicyUrl}#Refunds`" target="_blank">Article 11</a> de la <a :href="purchasePolicyUrl" target="_blank">Politique d'achat de Ticketmaster</a>. Votre réservation d'hébergement est soumise aux conditions d'annulation de l'hôtel et n'est pas automatiquement annulée ou remboursée. Vous devez déposer une demande d'annulation. Les suppléments tels que les souvenirs, la livraison accélérée et l'assurance ne sont pas remboursables. Nous ne sommes pas responsables des frais accessoires de voyage ou autres.
        </template>
    </div>
</template>

<script>
    import purchaseConstants from '@tenants/ticketmaster/app/utils/constants/purchase';
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';
    import EventCancellationPolicy_fr_packages_au from './EventCancellationPolicy_fr_packages_au';
    import EventCancellationPolicy_fr_packages from './EventCancellationPolicy_fr_packages';

    export default {
        components: {
            /* eslint-disable vue/no-unused-components */
            EventCancellationPolicy_fr_packages_au,
            EventCancellationPolicy_fr_packages
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            purchaseFlow: {
                type: String,
                default: ''
            },
            country: {
                type: String,
                default: ''
            }
        },

        computed: {
            purchasePolicyUrl( ) {
                return tenantUrlsConstants.purchasePolicy;
            },
            component( ) {
                const { purchaseFlow } = this;
                const { FLOWS } = purchaseConstants.CART;
                const isPackageOffer = purchaseFlow === FLOWS.PACKAGES || purchaseFlow === FLOWS.EXPERIENCES;

                if ( this.country === 'AU' && isPackageOffer ) {
                    return EventCancellationPolicy_fr_packages_au;
                } else if ( isPackageOffer ) {
                    return EventCancellationPolicy_fr_packages;
                } else {
                    return '';
                }
            }
        },
    };
</script>
