<template>
    <v-card
        class="gst-event-cancellation-policy-modal d-flex flex-column"
        :class="{
            'rounded-t-1': $vuetify.breakpoint.mdAndUp,
            'rounded-t-lg': !$vuetify.breakpoint.mdAndUp,
        }"
        color="text">
        <HeaderVariant1
            :title="$t( '_common:terms.eventCancellationPolicy' )"
            class="flex-grow-0"
            @close="$emit('close')" />
        <div class="d-flex flex-grow-1 flex-column px-6 py-4">
            <PrismicRichText
                v-if="modalContent"
                :field="modalContent" />
            <EventCancellationPolicy
                v-else
                :purchase-flow="cartPurchaseFlow"
                :country="cartPurchaseCountry" />
        </div>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import HeaderVariant1 from '@tenants/ticketmaster/app/components/modals/HeaderVariant1.vue';
    import EventCancellationPolicy from '@tenants/ticketmaster/app/static/EventCancellationPolicy/EventCancellationPolicy.vue';
    import CartPurchaseFlowMixin from '@tenant/app/mixins/CartPurchaseFlow';

    export default {
        name: 'EventCancellationPolicyModal',
        components: {
            HeaderVariant1,
            EventCancellationPolicy
        },
        mixins: [
            CloseModalOnRouteChangeMixin,
            CartPurchaseFlowMixin
        ],
        props: {
            modalContent: {
                type: Array,
                default: null
            }
        },
        computed: {
            ...mapGetters( {
                cartPurchaseCountry: 'cart/purchaseCountry'
            } )
        }
    };
</script>
