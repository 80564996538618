<template>
    <div class="gst-event-cancellation-policy__content">
        <component :is="component" v-if="component" />
        <template v-else>
            Si se cancela el evento, se le reembolsará su entrada de acuerdo con la <a :href="`${purchasePolicyUrl}#Canceled`" target="_blank">Sección 10</a> y la <a :href="`${purchasePolicyUrl}#Refunds`" target="_blank">Sección 11</a> de la <a :href="purchasePolicyUrl" target="_blank">Política de compras de Ticketmaster</a>. Su reserva de alojamiento queda sujeta a la política de cancelación del hotel, y no se cancelará ni se reembolsará automáticamente. Debe iniciar una solicitud de cancelación. Los complementos como recuerdos, envío urgente y seguros no son reembolsables. No nos hacemos responsables de viajes adicionales ni de otros gastos.
        </template>
    </div>
</template>

<script>
    import purchaseConstants from '@tenants/ticketmaster/app/utils/constants/purchase';
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';
    import EventCancellationPolicy_es_packages_au from './EventCancellationPolicy_es_packages_au';
    import EventCancellationPolicy_es_packages from './EventCancellationPolicy_es_packages';

    export default {
        components: {
            /* eslint-disable vue/no-unused-components */
            EventCancellationPolicy_es_packages_au,
            EventCancellationPolicy_es_packages
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            purchaseFlow: {
                type: String,
                default: ''
            },
            country: {
                type: String,
                default: ''
            }
        },

        computed: {
            purchasePolicyUrl( ) {
                return tenantUrlsConstants.purchasePolicy;
            },
            component( ) {
                const { purchaseFlow } = this;
                const { FLOWS } = purchaseConstants.CART;
                const isPackageOffer = purchaseFlow === FLOWS.PACKAGES || purchaseFlow === FLOWS.EXPERIENCES;

                if ( this.country === 'AU' && isPackageOffer ) {
                    return EventCancellationPolicy_es_packages_au;
                } else if ( isPackageOffer ) {
                    return EventCancellationPolicy_es_packages;
                } else {
                    return '';
                }
            }
        },
    };
</script>
