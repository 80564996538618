<template>
    <p>
        Eventos en vivo pueden cambiar debido a circunstancias fuera de nuestro control y algunas veces en el último momento. Si el evento es cancelado antes de que comience su paquete, este le será reembolsado de acuerdo con la Sección 6 de la <a :href="tenantUrlsConstantsForAu.purchasePolicy.en" target="_blank">Política de Compra</a>, incluyendo alojamiento y experiencias. Si el evento es cancelado en o después del día en que comienza su paquete, podrían aplicarse reembolsos parciales según lo detallado en la <a :href="tenantUrlsConstantsForAu.travelExperiencePolicy.en" target="_blank">Política de Viajes y Experiencias</a>. No nos hacemos responsables por gastos de viaje u otros gastos adicionales.
    </p>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        computed: {
            tenantUrlsConstantsForAu( ) {
                return tenantUrlsConstants.COUNTRIES.AU;
            }
        }
    };
</script>
