<template>
    <div class="gst-event-cancellation-policy__content">
        <component :is="component" v-if="component" />
        <template v-else>
            If the event is cancelled, you will be refunded for your ticket in accordance with <a :href="`${purchasePolicyUrl}#Canceled`" target="_blank">Section 10</a> and <a :href="`${purchasePolicyUrl}#Refunds`" target="_blank">Section 11</a> of the <a :href="purchasePolicyUrl" target="_blank">Ticketmaster Purchase Policy</a>. Your accommodation booking is subject to the hotel's cancellation policy and will not automatically be cancelled or refunded. You must initiate a cancellation request. Add-ons like memorabilia, expedited shipping, and insurance are not refundable. We are not liable for ancillary travel or other expenses.
        </template>
    </div>
</template>

<script>
    import purchaseConstants from '@tenants/ticketmaster/app/utils/constants/purchase';
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';
    import EventCancellationPolicy_en_packages_au from './EventCancellationPolicy_en_packages_au';
    import EventCancellationPolicy_en_packages from './EventCancellationPolicy_en_packages';

    export default {
        components: {
            /* eslint-disable vue/no-unused-components */
            EventCancellationPolicy_en_packages_au,
            EventCancellationPolicy_en_packages
            /* eslint-enable vue/no-unused-components */
        },
        props: {
            purchaseFlow: {
                type: String,
                default: ''
            },
            country: {
                type: String,
                default: ''
            }
        },

        computed: {
            purchasePolicyUrl( ) {
                return tenantUrlsConstants.purchasePolicy;
            },
            component( ) {
                const { purchaseFlow } = this;
                const { FLOWS } = purchaseConstants.CART;
                const isPackageOffer = purchaseFlow === FLOWS.PACKAGES || purchaseFlow === FLOWS.EXPERIENCES;

                if ( this.country === 'AU' && isPackageOffer ) {
                    return EventCancellationPolicy_en_packages_au;
                } else if ( isPackageOffer ) {
                    return EventCancellationPolicy_en_packages;
                } else {
                    return '';
                }
            }
        },
    };
</script>
