<template>
    <p>
        En cas d'annulation de l'événement, votre forfait vous est remboursé conformément à l'<a :href="`${purchasePolicyUrl}#Canceled`" target="_blank">Article 10</a> et l'<a :href="`${purchasePolicyUrl}#Refunds`" target="_blank">Article 11</a> de la <a :href="purchasePolicyUrl" target="_blank">Politique d'achat de Ticketmaster</a>, y compris l'hébergement. Les suppléments tels que les souvenirs, la livraison accélérée et l'assurance ne sont pas remboursables. Nous ne sommes pas responsables des frais accessoires de voyage ou autres.
    </p>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        computed: {
            purchasePolicyUrl( ) {
                return tenantUrlsConstants.purchasePolicy;
            }
        }
    };
</script>
