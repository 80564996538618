<template>
    <p>
        Live events can change due to circumstances outside our control, and sometimes at the last minute. If the event is cancelled before your package commences, you will be refunded for your package in accordance with Section 6 of the <a :href="tenantUrlsConstantsForAu.purchasePolicy.en" target="_blank">Purchase Policy</a> inclusive of accommodation and experiences. If the event is cancelled on or after the day that your package commences partial refunds may apply as detailed in the <a :href="tenantUrlsConstantsForAu.travelExperiencePolicy.en" target="_blank">Travel and Experiences Policy</a>. We are not liable for ancillary travel or other expenses.
    </p>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        computed: {
            tenantUrlsConstantsForAu( ) {
                return tenantUrlsConstants.COUNTRIES.AU;
            }
        }
    };
</script>
